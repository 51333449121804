import { defineStore } from "pinia";

// main is the name of the store. It is unique across your application
// and will appear in devtools
export const useGlobalStore = defineStore("global", {
  // a function that returns a fresh state
  state: () => ({
    globalData: {},
    clinicMapText: '',
    locale: useCookie("preferredCountry").value,
    localeDetail: {},
    locales: {},
    langCode: "en",
    defaultLocale: 'en-au',
    testMenu: [
      {
        title: useTranslateStore("mainmenu.testCategory.sexualHealth.title"),
        description: useTranslateStore("mainmenu.testCategory.sexualHealth.introText"),
        href: "/tests/sexual-health",
        showAustralia: true,
        items: [
          {
            title: `<b>${useTranslateStore("mainmenu.overview")}</b>`,
            hasChild: false,
            href: "/tests/sexual-health",
          }
        ],
      },
      {
        title: useTranslateStore("mainmenu.testCategory.healthAndWellness.title"),
        description: useTranslateStore("mainmenu.testCategory.healthAndWellness.introText"),
        href: "/tests/health-and-wellness",
        showAustralia: false,
        items: [
          {
            title: `<b>${useTranslateStore("mainmenu.overview")}</b>`,
            hasChild: false,
            href: "/tests/health-and-wellness",
          }
        ],
      },
    ],
    testHealthSubMenu: [],
    helpSubMenu: {},
    helpHealthSubMenu: [],
    helpSubMenuOverview: [
      {
        title: "<b>" + useTranslateStore("mainmenu.overview") + "</b>",
        href: "/help-and-advice/sexual-health-and-stis",
      },
    ],

    faqTopics: [
      {
        title: useTranslateStore("mainmenu.faqTopics.transmissionAndSymptoms"),
        href: "/faqs/worried-about-having-an-sti",
      },
      {
        title: useTranslateStore("mainmenu.faqTopics.bookingYourSTITest"),
        href: "/faqs/getting-tested",
      },
      {
        title: useTranslateStore("mainmenu.faqTopics.gettingTestResults"),
        href: "/faqs/getting-results",
      },
      {
        title: useTranslateStore("mainmenu.faqTopics.aftercare"),
        href: "/faqs/what-happens-next",
      },
    ],
    countrySwitcherVisibility: false,
    landbotChat: ''
  }),
  // optional getters
  getters: {
    // get site image
    siteSettings: (state) => state.globalData,
    siteDefaultLocale: (state) => state.defaultLocale,
    siteLogo: (state) => state.globalData.logo,
    siteCountry: (state) => state.globalData.site.country_name,
    siteCountryCode: (state) => state.globalData.site.country_code,
    siteMapConfigLatLng: (state) => ({
      latitude: Number(state.globalData.map_configuration.default_startup_lat),
      longitude: Number(state.globalData.map_configuration.default_startup_lon),
      range: Number(state.globalData.map_configuration.default_startup_range),
    }),
    siteClusterSettings: (state) => ({
      showCluster: state.globalData.marker_clustering,
      maxClusterZoom: state.globalData.maximum_clustering_zoom
    }),
    siteSearchZoom: (state) =>
      Number(state.globalData.map_configuration.search_zoom),
    siteDefaultZoom: (state) =>
      Number(state.globalData.map_configuration.default_startup_zoom),
    siteDefaultZoomMobile: (state) =>
      Number(state.globalData.map_configuration.default_startup_zoom_mobile),
    siteSearchRange: (state) =>
      Number(state.globalData.map_configuration.search_range),
    siteEverywhereService: (state) =>
      state.globalData.everywhere_service,
    sitePrimaryPhoneNumber: (state) => state.globalData.primary_phone_number,
    siteEverywhereAddressLookup: (state) => state.globalData.everywhere_address_lookup,
    siteCurrencySymbol: (state) => state.globalData.currency.symbol,
    siteCurrencySymbolPosition: (state) => state.globalData.currency_symbol_position.value,
    siteCurrencySymbolSpace: (state) => state.globalData.currency_symbol_space,
    siteCurrencyDecimal: (state) => state.globalData.currency_decimal,
    siteCurrentLocale: (state) => state.locale,
    siteCurrentLocaleDetail: (state) => state.localeDetail,
    siteAllLocales: (state) => state.locales,
    siteLangCode: (state) => state.langCode,
    sitehelpMenu: (state) => state.helpSubMenu,
    siteFaqTopicMenu: (state) => state.faqTopics,
    sitetestMenu: (state) => state.testMenu,
    siteMobileTestMenu: (state) => [
      {
        title: "<b>" + useTranslateStore("mainmenu.allTests") + "</b>",
        hasChild: false,
        href: "/tests",
        showAustralia: true,
      },
      ...state.testMenu,
    ],
    sitetestHealthSubMenu: (state) => state.testHealthSubMenu,
    siteMobileTestHealthSubMenu: (state) => [
      {
        title: "<b>Overview</b>",
        hasChild: false,
        href: "/tests/health-and-wellness",
      },
      ...state.testHealthSubMenu,
    ],
    sitehelpHealthSubMenu: (state) => state.helpHealthSubMenu,
    siteisAustralia: (state) => state.locale === "en-au",
    siteShowCustomCheckoutFieldsAustralia: (state) =>
      state.globalData.show_custom_checkout_fields_australia,
    patientLoginLink: (state) => state.globalData.patient_login_link,
    siteBlogLanguage: (state) => ["en", "es"],
    siteClinicTimeSlotDuration: (state) =>
      Number(state.globalData.clinic_time_slot_duration),
    siteClinicTimeFormat: (state) => state.globalData.clinic_time_format,
    siteClinicOpeningTimes: (state) => state.globalData.clinic_opening_times,
    siteEverywhereRegionLookUp: (state) => state.globalData.everywhere_region_lookup,
    siteClinicMapText: (state) => state.clinicMapText,
    sitetestSexualHealthSubMenu: (state) =>
      state.globalData.most_popular_tests.map((test) => {
        let category = "";
        if (test.primary_category) {
          let parent = "";
          if (test.primary_category.parent) {
            parent = test.primary_category.parent.slug + "/";
          }
          category = parent + test.primary_category.slug + "/";
        }

        return {
          title: test.title,
          description: test.summary,
          href: "/tests/" + category + test.slug,
          hasChild: false,
        };
      }),
    siteCountrySwitcherVisibility: (state) => state.countrySwitcherVisibility,
    landbotChatBox: (state) => state.landbotChat,
  },
  // optional actions
  actions: {
    setClinicMapSearchText(data) {
      this.clinicMapText = data
    },
    setCountrySwitcherVisibility(status) {
      this.countrySwitcherVisibility = status
    },
    setLandbotChatBox(object) {
      this.landbotChat = object
    },

    async registerGlobalData(data) {
      this.locales = await getLocales();
      const preferedCountry = useCookie('preferredCountry', {
        maxAge: 30*86400*2,
      })
      if(data == "en") {
        this.locale = preferedCountry.value ??this.defaultLocale
      }else {
        preferedCountry.value =  data
        this.locale = data
      }
      this.globalData = await useFetchConfiguration();

      this.localeDetail = Object.values(this.locales).find((data) =>
        data.languages.find((langCode) => langCode.code == this.locale)
      );
      //if (this.localeDetail) {
        // this.langCode = this.localeDetail.languages.find(
        //   (data) => data.code == this.locale
        // )?.lang;
    //  }

      const { data: topicData } = await useHelpAdviceSubmenu();

      // Extract titles and generate URLs using generateHelpAdviceURL function
      this.helpSubMenu = topicData.value.data.map(item => ({
        title: item.title,
        href: generateHelpAdviceURL(item)
      }));
      this.helpSubMenu = [...this.helpSubMenuOverview, ...this.helpSubMenu];

      if (this.siteSettings.categories.includes('health-and-wellness')) {
        /** Health & Wellness menu under tests **/
        const subCategories = await useFetchSubCategoryLists('health-and-wellness');
        
        this.testHealthSubMenu = subCategories.map(item => ({
          title: item.title,
          href: `/tests/health-and-wellness/${item.slug}`,
        }));

        /** Health & Wellness menu under Help & Advice **/
        const { data: helpsubCategories } = await useHelpAdviceSubCategories('health-and-wellness');

        this.helpHealthSubMenu = helpsubCategories.value.data.map(item => ({
          title: item.title,
          href: `/help-and-advice/health-and-wellness/${item.slug}`,
        }));
      }
    },

    async getIpLookUp() {
      this.locales = await getLocales();

      const dataWithoutExternalLink = Object.keys(this.locales).map(key => ({
        code: key, ...this.locales[key]
      })).filter(country => country.external_link == false);
      const geoLocationData = await useIpLookUp()
      const getLookUpLocale = geoLocationData?.value?.country_code
      if (getLookUpLocale) {
        const getLocale = dataWithoutExternalLink.find((data)=> data.code == getLookUpLocale)
        if (getLocale && getLocale.languages && getLocale.languages.length > 0) {
          return `${getLocale.primary_lang}-${(getLookUpLocale).toLowerCase()}`
        }
      }
      return this.defaultLocale
    },



    async getPosition(position) {
      const { data: result } = await useReverseGeocodingWithGoogle(
        position.coords.latitude,
        position.coords.longitude
      );
      const results = result.value.results;
      if (results.length > 0) {
        const addressComponents = results[0].address_components;
        const component = addressComponents.find((component) =>
          component.types.includes("country")
        );
        if (component) {
          const getCountry = Object.entries(this.locales)
            .map(([key, innerObj]) => {
              if (key == component.short_name) {
                return innerObj.languages;
              }
            })
            .find((data) => data);
          return getCountry[0].code;
        }
      }
    },
  },
});
