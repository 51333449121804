import revive_payload_client_4sVQNw7RlN from "/data/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/data/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/data/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/data/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_J8PkxsSQmi from "/data/node_modules/nuxt-snackbar/dist/runtime/plugin.mjs";
import plugin_vue3_A0OWXRrUgq from "/data/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/data/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/data/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/data/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/data/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/data/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/data/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_1UohGbtF8v from "/data/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import filters_znakZuvLqA from "/data/plugins/filters.js";
import flare_client_8OewLYGJqN from "/data/plugins/flare.client.ts";
import i18n_sVHQBgnb3t from "/data/plugins/i18n.js";
import sentry_client_shVUlIjFLk from "/data/plugins/sentry.client.ts";
import tawkMessenger_client_GnbJ52tFL3 from "/data/plugins/tawkMessenger.client.ts";
import v_calendar_client_hFB5p1S5zq from "/data/plugins/v-calendar.client.ts";
import veevalidate_components_HZdjzYOWQC from "/data/plugins/veevalidate-components.ts";
import veevalidate_rules_Xc2IpxFdQq from "/data/plugins/veevalidate-rules.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_J8PkxsSQmi,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_1UohGbtF8v,
  filters_znakZuvLqA,
  flare_client_8OewLYGJqN,
  i18n_sVHQBgnb3t,
  sentry_client_shVUlIjFLk,
  tawkMessenger_client_GnbJ52tFL3,
  v_calendar_client_hFB5p1S5zq,
  veevalidate_components_HZdjzYOWQC,
  veevalidate_rules_Xc2IpxFdQq
]