import TawkMessengerVue from "@tawk.to/tawk-messenger-vue-3";
import { useGlobalStore } from "#imports";

export default defineNuxtPlugin((nuxtApp) => {
	interface TawkConfig {
		tawk: {
			property_id: string;
			widget_id: string;
		};
		tawk_chat: boolean;
	}
	const globalStore = useGlobalStore();
	const tawkConfig = globalStore.globalData as TawkConfig;
	if (!tawkConfig.tawk_chat) return;
	nuxtApp.vueApp.use(TawkMessengerVue, {
		propertyId: tawkConfig.tawk.property_id,
		widgetId: tawkConfig.tawk.widget_id,
	});
});
