export const generateSchema = (page, data) => {

  let itemLists = [];

  if (page == 'breadcrumbs') {

    if (data.length > 0) {

      data.forEach(function (item, index) {
        let listItem = {
          "@type": "ListItem",
          "position": (index + 1),
          "name": item.title,
        }
        if (item.to) {
          listItem["item"] = useRuntimeConfig().public.i18n.baseUrl + localeUri(item.to);
        }
        itemLists.push(listItem);
      });
    }
  } else if (page == 'faqs-topic' || page == 'test-detail') {

    if (data.length > 0) {

      data.forEach(function (item, index) {
        let answerText = '';
        item.answer_text.forEach(function (answer, answerIndex) {
          if (answer.type == 'text') {
            answerText += answer.text;
          }      
        });

        let listItem = {
          "@type": "Question",
          "name": item.title,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": answerText
          }
        }
        itemLists.push(listItem);
      });
    }
  } else if (page == 'city' || page == 'help-and-advice-category') {
    if (data.length > 0) {

      data.forEach(function (item, index) {

        let question = '';
        let answer = '';
        if (page == 'help-and-advice-category') {
          question = item.title;
          answer = item.answer_text;
        } else {
          question = item.title_text;
          answer = item.body_text;
        }

        let listItem = {
          "@type": "Question",
          "name": question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": answer
          }
        }
        itemLists.push(listItem);
      });
    }
  }

  if (itemLists.length > 0) {

    let schemaHTML = {};
    if (page == 'breadcrumbs') {
      schemaHTML = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": itemLists
      }
    } else {
      schemaHTML = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": itemLists
      }
    }

    let scripts = [
      {
        type: "application/ld+json",
        innerHTML: JSON.stringify(schemaHTML)
      },
    ];

    useHead({
      script: scripts
    });
  }
};
