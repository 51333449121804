<template>
  <div>
    <CommonBreadCrumbs :data="breadcrumbs"> </CommonBreadCrumbs>
    <NuxtLayout name="center">
      <div
        class="text-center mt-10"
        :class="{
          'mb-10': props.error.statusCode == 500,
          'mb-20': props.error.statusCode != 500,
        }"
      >
        <h1 class="mb-10">
          {{ HTTP_ERRORS.get(props.error.statusCode, "Unknown Error") }}
        </h1>
        <p>
          <span
            class="md:max-w-4xl mx-auto"
            v-html="
              HTTP_ERRORS_MESSAGE.get(props.error.statusCode, 'Unknown Error')
            "
          >
          </span>
          <a
            v-if="props.error.statusCode == 500"
            class="text-secondary underline ml-1"
            href="mailto:info@better2know.com"
          >
            {{ $t("error.supportTeam") }}.
          </a>
        </p>
      </div>
    </NuxtLayout>
    <div class="bg-stone-white">
      <NuxtLayout name="center">
        <div class="flex justify-center py-10 md:py-16">
          <BaseButton
            class="md:w-80 w-full h-16 mx-0 text-lg"
            :text="$t('error.homeBtnTitle')"
            @click="handleClearErrors"
          />
        </div>
      </NuxtLayout>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RequestError } from "~/types/general";

const { t } = useI18n();

export interface ErrorProps {
  error: RequestError;
}

const props = defineProps<ErrorProps>();

const HTTP_ERRORS = {
  500: t("error.title.500"),
  404: t("error.title.404"),
  403: t("error.title.403"),
  401: t("error.title.401"),
  400: t("error.title.400"),

  get: <T>(code: number, fallback?: T) => {
    return HTTP_ERRORS[code as keyof typeof HTTP_ERRORS] ?? fallback;
  },
} as const;

const HTTP_ERRORS_MESSAGE = {
  500: t("error.message.500"),
  404: t("error.message.404"),
  403: t("error.message.403"),
  401: t("error.message.401"),
  400: t("error.message.400"),

  get: <T>(code: number, fallback?: T) => {
    return (
      HTTP_ERRORS_MESSAGE[code as keyof typeof HTTP_ERRORS_MESSAGE] ?? fallback
    );
  },
} as const;

const handleClearErrors = () => {
  const locale = useLocaleRoute();
  const nextUrl = locale('/');
  reloadNuxtApp({
    path: `${nextUrl?.path}/`,
    force: true
  });
};
const breadcrumbs = getBreadcrumbs("error-" + props.error.statusCode);
</script>
