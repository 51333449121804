export const useScreenSize = (screeSize = "") => {
  const screens = {
    sm: "576px",
    // => @media (min-width: 640px) { ... }

    md: "768px",
    // => @media (min-width: 768px) { ... }

    lg: "1024px",
    // => @media (min-width: 1024px) { ... }

    xl: "1440px",
    // => @media (min-width: 1440px) { ... }

    "2xl": "1920px",
  };

  // create a keyed object of screens that match
  const matches = Object.entries(screens).reduce((results, [name, size]) => {
    const mediaQuery = `(max-width: ${size})`;

    if (process.client) {
      results[name] = window.matchMedia(mediaQuery).matches;
    }
    return results;
  }, {});

  // invalid screen choice
  if (!screens[screeSize]) {
    console.error(`No match for "${screeSize}"`);
    return false;
  }

  return matches[screeSize];
};

export const useFetchConfiguration = async () => {
  const { data: globalData } = await useFetchSafely(() => `site-settings`);
  return globalData.value?.data;
};

// http://bupstatamic.siteserver.space/api/sites
export const getLocales = async () => {
  const { data: result } = await useFetchSafely(() => "sites", {
    baseURL: `${useRuntimeConfig().public.apiBase}`,
  });
  return result.value.data;
};

export const useHomePage = async () => {
  return useFetchSafely(() => ``);
};

export const useServiceListType = async () => {
  return useFetchSafely(`/service-types`);
};

export const useHowItWorkServices = async () => {
  return useFetchSafely(() => `service-types/how-it-works`);
};

export const useConsultation = async () => {
  return useFetchSafely(() => `consultation/categories`);
};

export const useBannerNotifications = async () => {
  return useFetchSafely(() => `banners`);
};

export const useReverseGeocodingWithGoogle = async (lat, long) => {
  return useFetchSafely(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&loading=async&key=${
      useRuntimeConfig().public.apiGoogleMapKey
    }`
  );
};

export const useSiteSwitching = async (data) => {
  return useFetchSafely(() => "site-switching", {
    method: "post",
    params: data,
    lazy: true,
    server: false,
  });
};

export const useIpLookUp = async () => {
  const headers = useRequestHeaders();
  const response = await useFetch(
    `https://api.ipdata.co/${headers["x-forwarded-for"]}?api-key=${
      useRuntimeConfig().public.apiIpLookup
    }`
  );
  return response.data;
};

/**
 * Gets the list of categories.
 * 
 * @typedef {{
 * id: number;
 * title: string;
 * slug: string;
 * icon: Icon;
 * intro_text: string;
 * parent?: string;
 * }} HelpYouCategory;
 * 
 * @typedef {{
 * alt?: string;
 * title: string;
 * caption?: string;
 * attribution?: string;
 * url: string;
 * permalink: string;
 * }} Icon
 * @returns {Promise<HelpYouCategory[]>}
 */
export const useGetStartedSections = async () => {
  const { data: categoryDetails } = await useFetchSafely(() => `/categories`);
  return categoryDetails.value.data;
};
