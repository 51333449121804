export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-16"}],"link":[{"rel":"icon","sizes":"48x48","href":"/icons/favicon/favicon.ico"},{"rel":"icon","sizes":"any","type":"image/svg+xml","href":"/icons/favicon/favicon.svg"},{"rel":"apple-touch-icon","href":"/icons/favicon/apple-touch-icon.png"},{"rel":"manifest","href":"/icons/favicon/site.webmanifest"},{"rel":"mask-icon","color":"#5bbad5","href":"/icons/favicon/safari-pinned-tab.svg"}],"style":[],"script":[],"noscript":[],"charset":"utf-16","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'