export const getBreadcrumbs = (page, data) => {
  switch (page) {
    case 'contact-us':
      return [
        {
          "title": useTranslateStore("breadcrumb.contactUs"),
          "to": ""
        }
      ];

    case 'about-us':
      return [
        {
          "title": useTranslateStore("breadcrumb.aboutUs"),
          "to": ""
        }
      ];

    case 'in-the-media':
    case 'awards':
      return [
        {
          "title": useTranslateStore("breadcrumb.aboutUs"),
          "to": "/about-us"
        },
        {
          "title": useTranslateStore(`breadcrumb.${page === 'in-the-media' ? 'inTheMedia' : 'awards'}`),
          "to": ""
        }
      ];

    case 'clinics':
      return [
        {
          "title": useTranslateStore("breadcrumb.clinics"),
          "to": ""
        }
      ];

    case 'city':
      return [
        {
          "title": useTranslateStore("breadcrumb.clinics"),
          "to": "/clinics"
        },
        {
          "title": data.title,
          "to": ""
        }
      ];

    case 'faqs':
      return [
        {
          "title": useTranslateStore("breadcrumb.faqs"),
          "to": ""
        }
      ];

    case 'faqs-topic':
      return [
        {
          "title": useTranslateStore("breadcrumb.faqs"),
          "to": "/faqs"
        },
        {
          "title": (data.topic) ? data.topic.title : null,
          "to": ""
        }
      ];

    case 'get-started':
      return [
        {
          "title": useTranslateStore("breadcrumb.getStarted"),
          "to": ""
        }
      ];

    case 'reviews':
      return [
        {
          "title": useTranslateStore("breadcrumb.reviews"),
          "to": ""
        }
      ];

    case 'legal-and-privacy':
      return [
        {
          "title": useTranslateStore("breadcrumb.legalAndPrivacy"),
          "to": ""
        }
      ];

    case 'legal-and-privacy-topic':
      return [
        {
          "title": useTranslateStore("breadcrumb.legalAndPrivacy"),
          "to": "/legal-and-privacy"
        },
        {
          "title": data.title,
          "to": ""
        }
      ];

    case 'help-and-advice':
      return [
        {
          "title": useTranslateStore("breadcrumb.helpAndAdvice"),
          "to": ""
        }
      ];

    case 'help-and-advice-category':
      return [
        {
          "title": useTranslateStore("breadcrumb.helpAndAdvice"),
          "to": "/help-and-advice"
        },
        {
          "title": data.title,
          "to": ""
        }
      ];

    case 'help-and-advice-subcategory':
      let breadcrumbList = [
        {
          "title": useTranslateStore("breadcrumb.helpAndAdvice"),
          "to": "/help-and-advice"
        }
      ];

      if (data.parent) {
        breadcrumbList.push({
          "title": data.parent.title,
          "to": `/help-and-advice/${data.parent.slug}`
        });
      }

      breadcrumbList.push({
        "title": data.title,
        "to": ""
      });

      return breadcrumbList;

    case 'condition':
      let conditionBreadcrumbList = [
        {
          "title": useTranslateStore("breadcrumb.helpAndAdvice"),
          "to": "/help-and-advice"
        }
      ];

      if (data.primary_category) {
        let parent = '';
        if (data.primary_category.parent) {
          conditionBreadcrumbList.push({
            "title": data.primary_category.parent.title,
            "to": `/help-and-advice/${data.primary_category.parent.slug}`
          });
          parent = data.primary_category.parent.slug + "/";
        }

        conditionBreadcrumbList.push({
          "title": data.primary_category.title,
          "to": `/help-and-advice/${parent}${data.primary_category.slug}`
        });
      }

      conditionBreadcrumbList.push({
        "title": data.title,
        "to": ""
      });

      return conditionBreadcrumbList;

    case 'tests':
      return [
        {
          "title": useTranslateStore("breadcrumb.tests"),
          "to": ""
        }
      ];

    case 'test-category':
      let testBreadcrumbList = [
        {
          "title": useTranslateStore("breadcrumb.tests"),
          "to": "/tests"
        }
      ];

      if (data.parent) {
        testBreadcrumbList.push({
          "title": data.parent.title,
          "to": `/tests/${data.parent.slug}`
        });
      }

      testBreadcrumbList.push({
        "title": data.title,
        "to": ""
      });

      return testBreadcrumbList;

    case 'test-detail':
      let testDetailBreadcrumbList = [
        {
          "title": useTranslateStore("breadcrumb.tests"),
          "to": "/tests"
        }
      ];

      if (data.primary_category) {
        let parent = '';
        if (data.primary_category.parent) {
          testDetailBreadcrumbList.push({
            "title": data.primary_category.parent.title,
            "to": `/tests/${data.primary_category.parent.slug}`
          });
          parent = data.primary_category.parent.slug + "/";
        }

        testDetailBreadcrumbList.push({
          "title": data.primary_category.title,
          "to": `/tests/${parent}${data.primary_category.slug}`
        });
      }

      testDetailBreadcrumbList.push({
        "title": data.title,
        "to": ""
      });

      return testDetailBreadcrumbList;

    case 'blog':
      return [
        {
          "title": useTranslateStore("breadcrumb.blog"),
          "to": ""
        }
      ];

    case 'blog-detail':
      return [
        {
          "title": useTranslateStore("breadcrumb.blog"),
          "to": "/blog"
        },
        {
          "title": data.title,
          "to": ""
        }
      ];

    case 'blog-authors':
      return [
        {
          "title": useTranslateStore("breadcrumb.blog"),
          "to": "/blog"
        },
        {
          "title": useTranslateStore("breadcrumb.authors"),
          "to": ""
        }
      ];

    case 'blog-author-detail':
      return [
        {
          "title": useTranslateStore("breadcrumb.blog"),
          "to": "/blog"
        },
        {
          "title": useTranslateStore("breadcrumb.authors"),
          "to": "/blog/authors"
        },
        {
          "title": (data.display_name) ? data.display_name : null,
          "to": ""
        }
      ];

    case 'blog-category':
      return [
        {
          "title": useTranslateStore("breadcrumb.blog"),
          "to": "/blog"
        },
        {
          "title": (data.name) ? data.name : null,
          "to": ""
        }
      ];

    case 'blog-tag':
      return [
        {
          "title": useTranslateStore("breadcrumb.blog"),
          "to": "/blog"
        },
        {
          "title": (data.name) ? data.name : null,
          "to": ""
        }
      ];

    case 'error-404':
      return [
        {
          "title": useTranslateStore("breadcrumb.404"),
          "to": ""
        }
      ];

    case 'error-500':
      return [
        {
          "title": useTranslateStore("breadcrumb.500"),
          "to": ""
        }
      ];

    case 'error-403':
      return [
        {
          "title": useTranslateStore("breadcrumb.403"),
          "to": ""
        }
      ];

    case 'error-401':
      return [
        {
          "title": useTranslateStore("breadcrumb.401"),
          "to": ""
        }
      ];

    case 'error-400':
      return [
        {
          "title": useTranslateStore("breadcrumb.400"),
          "to": ""
        }
      ];

    default:
      return {};
  }
};
