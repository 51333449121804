<template>
  <NuxtLayout name="error">
    <CommonError :error="props.error" />
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { RequestError } from "./types/general";

interface ErrorProps {
  error: RequestError;
}

const props = defineProps<ErrorProps>();

const store = useGlobalStore();
const localeRoute = useLocaleRoute();
const routeLocale = computed(() => localeRoute('/')?.path.slice(1));
const currentLocale = computed(() => i18nRedirect.value ?? routeLocale.value ?? store.siteDefaultLocale)

const i18nRedirect = useCookie(
  'i18n_redirected',
  {
    watch: true,
  }
);
  
await store.registerGlobalData(currentLocale.value);
// IMPORTANT Log, DO NOT REMOVE IT!
const errorState = useState();
errorState.value = props.error;
console.log(errorState.value);

useHead({
  title: `Better2Know - Error ${props.error.statusCode}`,
});
</script>
