const id = "page-transition-pollyfill";

export default defineNuxtRouteMiddleware(() => {
  // Only apply the pollyfill to client side requests.
  if (!import.meta.client) return;

  // Get access control to layout store.
  const layoutStore = useLayoutStore();

  // Get root container
  const rootContainer = document.querySelector(layoutStore.targetSelector);

  // Get pollyfill style tag or create a brand new one.
  const pollyfillStyleTag = getTransitionPollyfillStyleTag();

  // Abort mission, if one of the required tags is missing.
  if (!rootContainer || !pollyfillStyleTag) return;

  // Prefill the root container with the required parameters.
  rootContainer.setAttribute('data-page-transition-pollyfill-id', id);
  rootContainer.setAttribute('data-disabled', String(!layoutStore.shouldPollyfillPageTransition));

  // Real magic starts from here!
  const { hook } = useNuxtApp();
  hook('page:start', () => {
    rootContainer.setAttribute('data-state', 'loading');
  });
  hook('page:finish', () => {
    rootContainer.setAttribute('data-state', 'idle');
  });
});

/**
 * Gets an instance of style tag element that has the page transition pollyfill parameters.
 * @returns {Element}
 */
function getTransitionPollyfillStyleTag(): Element {
  return (
    document.querySelector(`style[id='${id}']`) ??
    createTransitionPollyfillStyleTag()
  );
}

/**
 * Creates a new element and fills the required parameters.
 *
 * @returns {Element}
 */
function createTransitionPollyfillStyleTag(): Element {
  const styleTag = document.createElement("style");
  styleTag.id = id;
  styleTag.innerHTML = `
    [data-page-transition-pollyfill-id="${id}"] {
      opacity: 1;
      transition: opacity 500ms ease-in-out;
    }

    [data-page-transition-pollyfill-id="${id}"][data-state="loading"]:not([data-disabled="true"]) {
      opacity: 0;
    }
  `.trim();

  document.head.appendChild(styleTag);
  return styleTag;
}