import { flare } from "@flareapp/flare-client";


// Only enable Flare in production, we don't want to waste your quota while you're developing:
//if (process.env.NODE_ENV === 'production') {

    export default defineNuxtPlugin((nuxtApp) => {
      const config = useRuntimeConfig();
      if (config.public.flareEnv == 'production') {
      flare.light(config.public.flareKey);
      return {
        provide: {
          flare: flare,
        },
      };
    }
    });

//}