import { useGlobalStore } from "@/stores/global";

export default defineNuxtRouteMiddleware(async (to) => {
  // Set the status code to 404 for a custom 404 page
  const store = useGlobalStore();
  let splitPath = to.path.split("/")?.map((data) => `/${data}`);
  //set cookie for 2 months
  const preferedCountry = useCookie("preferredCountry", {
    maxAge: 30 * 86400 * 2,
    watch: true,
  });

  const { $localeRoute } = useNuxtApp();

  if (to.path == "/" && !preferedCountry.value) {
    if (process.server) {
      const ipLookupData = await store.getIpLookUp();
      const routeUrl = $localeRoute(to.path, ipLookupData);
      return navigateTo(routeUrl?.fullPath + "/", { redirectCode: 301 });
    }
  } else if (to.path == "/" && preferedCountry.value) {
    const routeUrl = $localeRoute(to.path, preferedCountry.value);
    return navigateTo(routeUrl?.fullPath + "/", { redirectCode: 301 });
  }
  
  // Add the trailing slash to the url for only homepage /en-au -> /en-au/
  if (
    to.path !== "/" &&
    !to.path.endsWith("/") &&
    to.path.endsWith(store.siteCurrentLocale ?? "en-au")
  ) {
    const { path, query, hash } = to;
    const nextPath = path + "/";
    const nextRoute = { path: nextPath, query, hash };
    return navigateTo(nextRoute, { redirectCode: 301 });
  }

  // Set the status code to 404 for a custom 404 page
  if (
    splitPath.some((word) => ["/en", "/es"].includes(word)) &&
    !to.path.includes("/blog")
  ) {
    throw createError({ statusCode: 404, statusMessage: "Page Not Found" });
  }
});
