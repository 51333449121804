export default {
  center: () => import("/data/layouts/center.vue").then(m => m.default || m),
  checkout: () => import("/data/layouts/checkout.vue").then(m => m.default || m),
  clinic: () => import("/data/layouts/clinic.vue").then(m => m.default || m),
  "compact-center": () => import("/data/layouts/compact-center.vue").then(m => m.default || m),
  default: () => import("/data/layouts/default.vue").then(m => m.default || m),
  error: () => import("/data/layouts/error.vue").then(m => m.default || m),
  full: () => import("/data/layouts/full.vue").then(m => m.default || m),
  "grid-center": () => import("/data/layouts/grid_center.vue").then(m => m.default || m),
  help: () => import("/data/layouts/help.vue").then(m => m.default || m),
  "side-filters": () => import("/data/layouts/side-filters.vue").then(m => m.default || m),
  stretch: () => import("/data/layouts/stretch.vue").then(m => m.default || m)
}