import { defineStore } from "pinia";

// main is the name of the store. It is unique across your application
// and will appear in devtools


interface Store {
  targetSelector: string;
  shouldPollyfillPageTransition: boolean;
}

export const useLayoutStore = defineStore("layout-store", {
  state: () =>
    ({
      targetSelector: 'main',
      shouldPollyfillPageTransition: true
    } as Store),

  actions: {
    enablePageTransition() {
      this.shouldPollyfillPageTransition = true;
    },
    disablePageTransitionState() {
      this.shouldPollyfillPageTransition = false;
    }
  },
});
