import { defineStore } from "pinia";

export const useMegaMenuStore = defineStore("megamenu", () => {
  const activeSection = ref<string>();
  const setActiveSection = (newActiveSection: typeof activeSection.value) =>
    (activeSection.value = newActiveSection);

  return {
    activeSection,
    setActiveSection,
  };
});
