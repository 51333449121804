
import { useGlobalStore } from '@/stores/global'

export default defineNuxtPlugin((/* nuxtApp */) => {
  return {
    provide: {
      truncate: (text, length, suffix) => {
        text = text.replace(/<\/?[^>]+>/ig, "-")
        if (text.length > length) {
          return text.substring(0, length) + suffix;
        } else {
          return text;
        }
      }, firstLetterCapital: (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text
      }, formatCurrency: (text) => {
        const globalStore = useGlobalStore();
        let space = (globalStore.siteCurrencySymbolSpace) ? ' ' : '';
        return (globalStore.siteCurrencySymbolPosition == 'after') ?`${text}${space}${globalStore.siteCurrencySymbol}` : `${globalStore.siteCurrencySymbol}${space}${text}`;
      }, titleCase: (str) => {
        return str.toLowerCase().split(' ').map(function (word) {
          return word.charAt(0).toUpperCase() + word.substring(1);
        }).join(' ');
      }, stripHTML: (str) => {
        let result = str.replaceAll('<br>',"").replaceAll('<p></p>', '').replaceAll('<li></li>', '');
        return result
      }, convertSlugToTitle: (str) => {
        let result =str.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    
        return result
      }

    }
  }
})
