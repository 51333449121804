export const useFaqTopicList = async () => {
  return useFetchSafely(() => `general_faq_topics/topics`);
};

export const useFaqDetail = async (slug) => {
  return useFetchSafely(() => `faqs_general/${slug}`);
};

export const useFaqIntro = async () => {
  return useFetchSafely(() => `pages/faqs`);
};

export const useConditionDetail = async (slug) => {
  return useFetchSafely(() => `conditions/${slug}`);
};

export const useLegalIntro = async () => {
  return useFetchSafely(() => `legals/legal-and-privacy`);
};

export const useListOfLegalItems = async () => {
  return useFetchSafely(() => `legal-policies`);
};

export const useLegalDetail = async (slug) => {
  return useFetchSafely(() => `legals/${slug}`);
};
export const useSubmitContactForm = async (data) => {
  return useFetchSafely(() => `forms/contact-us`, {
    method: "POST",
    body: data,
  });
};
export const useHelpAdviceDetail = async () => {
  return useFetchSafely(() => `help-and-advice`);
};

export const useHelpAdviceDetailSlug = async (slug) => {
  return useFetchSafely(() => `help-and-advice/${slug}`);
};

export const useUSPItems = async (slug) => {
  return useFetchSafely(() => `usp-items`);
};

export const useHelpAdviceSubmenu = async () => {
  return useFetchSafely(() => `conditions`);
};

export const useHelpAdviceSubCategories = async (slug) => {
  return useFetchSafely(() => `help-and-advice/sub-categories/${slug}`);
};