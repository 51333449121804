<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <NuxtSnackbar />
</template>

<script setup>
import { useGlobalStore } from "@/stores/global";

const route = useRoute();
const globalStore = useGlobalStore();

const { locale } = useI18n();
await globalStore.registerGlobalData(locale.value);

useHead({
  htmlAttrs: { lang: locale.value },
  title: route.meta.title,
});
</script>

<style>
.global-enter-active,
.global-leave-active {
  transition: opacity 0.4s;
}
.global-enter-from,
.global-leave-to {
  opacity: 0;
}
</style>
