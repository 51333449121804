
export const useFilterOptions = async (params) => {
  const { data: filterOptions } = await useFetchSafely(`tests/filter-options`, {
    params
  });
  return filterOptions.value?.data;
};

/**
 * Fetchs all the products from backend api.
 * @param {Partial<{ hiddenTests: boolean; clinic: number; service_type[number]: string }>} params - Request parameters.
 * @returns Products list.
 */
export const useFetchProductList = async (params) => {
  return useFetchSafely(`tests/list`, {
    params
  });
};

export const useFetchClinicTests = async (data, serviceType) => {
  let url = `tests/list?clinic=${data}`;
  
  if (data === 'everywhere-nurse-service') {
    url = `tests/list?page=1&sort=order-asc&service_type[0]=${data}`;
  } else if (serviceType) {
    url += `&service_type[0]=${serviceType}`;
  }
  
  return useFetchSafely(() => url);
};

export const useFetchCategoryDetails = async (slug) => {
  const { data: categoryDetails } = await useFetchSafely(
    () => `/categories/${slug}`
  );
  return categoryDetails.value.data;
};

export const useFetchSubCategoryLists = async (slug) => {
  const { data: subCategoryLists } = await useFetchSafely(
    () => `/sub-categories/${slug}`
  );
  return subCategoryLists.value.data;
};



export const useCheckEverywhereService = (lat, lng) => {
  return useFetchSafely(() => `/check-everywhere-service`,
    {
      body: { 'latitude': lat, 'longitude': lng },
      method: "POST",
    }
  );

};

export const useEveryWhereRegionPriceList = async () => {
  return await useFetchSafely(() => `/everywhere-service/regions`);
};
